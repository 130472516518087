import { formCookieKey, getCookie } from '@cookies'
import { Box, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import Icon from '@system/icon'
import React, { useState } from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles(() => ({
  checklistContainer: {
    pointerEvents: 'none',
  },
}))

const GatedContentModule = (props) => {
  const {
    blok: { preGatedContent, postGatedContent, gateUX },
  } = props

  const classes = useStyles(props)
  const [open, setOpen] = useState(false)
  const handleClose = (e) => {
    e.preventDefault()

    setOpen(false)
  }

  const handleOpen = () => setOpen(true)
  const savedValues = getCookie(formCookieKey)

  return (
    <SbEditable content={props.blok}>
      {!!preGatedContent && !!preGatedContent.length && !savedValues && (
        <Box onClick={handleOpen}>
          <Box className={classes.checklistContainer}>
            {renderBloks(preGatedContent)}
          </Box>
        </Box>
      )}
      {!!postGatedContent && !!postGatedContent.length && !!savedValues && (
        <Box>{renderBloks(postGatedContent)}</Box>
      )}

      {gateUX[0]?.component === 'interactiveFormModule' ? (
        renderBlok(gateUX[0], { open: open, handleClose })
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modalBackdrop}
        >
          <Box className={classes.modalRoot} disabled>
            <Box className={classes.closeIconContainer} onClick={handleClose}>
              <Icon styles={classes.closeIcon}>CloseIcon</Icon>
            </Box>

            <Box className={classes.checklistContainer}>
              {renderBloks(gateUX)}
            </Box>
          </Box>
        </Modal>
      )}
    </SbEditable>
  )
}

export default GatedContentModule
